import React, {useState} from 'react';
import logo from '../../assets/logo.png';
import './Navbar.css';

const Navbar: React.FC = () => {

  const [activeSection, setActiveSection] = useState('banner');

  const handleSetActive = (section: string) => setActiveSection(section);

  return (
    <nav className="navbar">
      <div className="logo">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <ul className="nav-links">
        <a className={activeSection === 'banner' ? 'link active' : 'link'} href="#banner" onClick={() => handleSetActive('banner')}>Home</a>
        <a className={activeSection === 'about' ? 'link active' : 'link'} href="#about" onClick={() => handleSetActive('about')}>Sobre</a>
        <a className={activeSection === 'services' ? 'link active' : 'link'} href="#services" onClick={() => handleSetActive('services')}>Serviços</a>
        <a className={activeSection === 'contact' ? 'link active' : 'link'} href="#contact" onClick={() => handleSetActive('contact')}>Contato</a>
      </ul>
    </nav>
  );
};

export default Navbar;