import React from 'react';
import './Banner.css';
import Button from '../../components/Button/Button';

const Banner: React.FC = () => {

  const BannerStyle = {
    fontSize: 20, 
    paddingLeft: 50, 
    paddingRight: 50, 
    marginTop: 20
  }

  return (
    <div className="parallax-banner section" id="banner">
      <div className="banner-content">
        <h1>Aprimore seus resultados, <br/> eleve sua precisão:</h1>
        <h2>Treinamento especializado <br/> em fertilidade masculina.</h2>
        <a href="#services">
          <Button onPress={() => null} style={BannerStyle}>
            Solicitar treinamento
          </Button>
        </a>
      </div>
    </div>
  );
}

export default Banner;